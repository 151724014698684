import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { IBarcode, Scanner, IDecoder } from "@impactdk/barcode-scanner";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface IReactBarcodeScannerRef {
  start(): void;
  stop(): void;
}

export interface IReactBarcodeScannerProps {
  /**
   * @description
   * Props passed to underlying video element. ID is reserved for DOM access.
   */
  videoProps?: Omit<React.VideoHTMLAttributes<HTMLVideoElement>, "id" | "src">;
  /**
   * @description
   * Callback for when barcode is identified.
   */
  onFindBarcode: (barcode: string) => void;
  /**
   * @description
   * Supply decoder responsible for decoding the canvas context made by the scanner.
   *
   * @example
   * import { WasmDecoder } from "@impactdk/barcode-scanner";
   *
   * const decoder = WasmDecoder.getInstance("/public-path/assets"); // Path to assets installed through included cli.
   */
  decoder: IDecoder;
  /**
   * @description
   * If false, the consumer is responsible for starting the scanner through ref public API.
   *
   * @default true
   */
  initOnMount?: boolean;
}

const videoId = "scanner-video";

/**
 * @example
 * import { WasmDecoder } from "@impactdk/barcode-scanner";
 * import { ReactBarcodeScanner, IReactBarcodeScannerRef } from "@impactdk/react-barcode-scanner";
 *
 * const scannerRef = useRef<IReactBarcodeScannerRef>(); // Access to public API, i.e. scannerRef?.start() or scannerRef?.stop().
 *
 * const decoder = WasmDecoder.getInstance("/public-path/asssets/barcode-scanner"); // Path to installed assets.
 *
 * return <ReactBarcodeScanner decoder={decoder} onFindBarcode={setBarcode} ref={scannerRef} />;
 */
export const ReactBarcodeScanner = forwardRef<IReactBarcodeScannerRef, IReactBarcodeScannerProps>((props, ref) => {
  const { videoProps, onFindBarcode, decoder, initOnMount = true } = props;

  const scannerRef = useRef<Scanner | null>(null);

  useEffect(() => {
    if (initOnMount) {
      startScanner();
    }

    return handleUnmount;
  }, []);

  useImperativeHandle(ref, () => ({
    start: startScanner,
    stop: stopScanner
  }));

  function startScanner(): void {
    if (scannerRef.current) {
      return;
    }

    const video = document.getElementById(videoId) as HTMLVideoElement;

    scannerRef.current = new Scanner(video, decoder, (() => {
      let previousValue: string;

      return ({ rawValue }: IBarcode) => {
        // Compare with previous value, to ensure the correct one has been found.
        if (!previousValue || rawValue !== previousValue) {
          previousValue = rawValue;
          return;
        }

        onFindBarcode(rawValue);
      };
    })());

    scannerRef.current.start();
  }

  function stopScanner(): void {
    if (scannerRef.current != null) {
      scannerRef.current.stop();
      scannerRef.current = null;
    }
  }

  function handleUnmount(): void {
    stopScanner();
  }

  return (
    <video {...videoProps} id={videoId} />
  );
});
