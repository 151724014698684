import rawr from "rawr";
import transport from "rawr/transports/worker";

import { IBarcode } from './barcode';

const workerFileName = "wasm-worker.js";

export interface IDecoder {
  decode(context: CanvasRenderingContext2D): IBarcode;
}

function sanitizeDirString(s: string): string {
  return s.replace(/\/$/, "");
}

export class WasmDecoder implements IDecoder {
  private static instance: WasmDecoder;
  private wasmDecoder: any;
  private worker: Worker;

  private constructor(installationDir: string = "") {
    const workerFilePath = `${installationDir}/${workerFileName}`;
    this.worker = new Worker(workerFilePath);

    this.wasmDecoder = rawr({ transport: transport(this.worker) });
  }

  public static getInstance(installationDir: string = ""): WasmDecoder {
    if (!WasmDecoder.instance) {
      const sanitized = sanitizeDirString(installationDir);
      WasmDecoder.instance = new WasmDecoder(sanitized);
    }

    return WasmDecoder.instance;
  }

  public static removeInstance(): void {
    WasmDecoder.instance.dispose();
    WasmDecoder.instance = null;
  }

  public decode(context: CanvasRenderingContext2D): IBarcode {
    const canvas = context.canvas;
    const width = canvas.width;
    const height = canvas.height;
    const imageData = context.getImageData(0, 0, width, height);

    return this.wasmDecoder.methods.detectUrl(width, height, imageData);
  }

  private dispose(): void {
    this.wasmDecoder = null;
    this.worker.terminate();
  }
}


