import { IDecoder } from "../decoder/decoder";
import { IBarcode } from '../decoder/barcode';

export class Scanner {

  private decodeCanvas: HTMLCanvasElement;
  private constraints: MediaStreamConstraints = {
    video: {
      facingMode: "environment"
    },
    audio: false
  };
  private running = false;

  constructor(private videoElement: HTMLVideoElement, private decoder: IDecoder, private cb: (barcode: IBarcode) => void) {
    this.decodeCanvas = document.createElement("canvas");

    this.onLoadedData = this.onLoadedData.bind(this);
    this.getOnFrameHandler = this.getOnFrameHandler.bind(this);
  }

  public async start(): Promise<void> {
    this.running = true;
    this.videoElement = this.videoElement;

    this.videoElement.addEventListener("loadeddata", this.onLoadedData);

    // Ensure correct attributes
    this.videoElement.setAttribute("autoplay", "");
    this.videoElement.setAttribute("muted", "");
    this.videoElement.setAttribute("playsinline", "");

    const stream = await navigator.mediaDevices.getUserMedia(this.constraints);

    this.videoElement.srcObject = stream;

    return new Promise<void>((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    });

  }

  public stop() {
    if (!this.videoElement || !this.videoElement.srcObject) { return; }

    this.videoElement.removeEventListener("loadeddata", this.onLoadedData);

    const ms = this.videoElement.srcObject as MediaStream;
    ms.getTracks().forEach(t => t.stop());

    this.videoElement.srcObject = null;
    this.running = false;
  }

  private resolve: () => void = () => null;
  private reject: (reason?: any) => void = () => null;

  private async onLoadedData() {
    if (!this.decodeCanvas || !this.videoElement) { this.reject(); return; }

    this.decodeCanvas.height = this.videoElement.videoHeight;
    this.decodeCanvas.width = this.videoElement.videoWidth;

    requestAnimationFrame(this.getOnFrameHandler());
  }

  private getOnFrameHandler(): () => void {
    if (!this.decodeCanvas) { return () => null; }

    this.resolve();

    const decodeCtx = this.decodeCanvas.getContext("2d");

    const onFrame = async () => {
      if (!this.decodeCanvas || !this.videoElement) { return; }

      decodeCtx.drawImage(this.videoElement, 0, 0, this.videoElement.videoWidth, this.videoElement.videoHeight);
      let barcode: IBarcode = null;

      try {
        barcode = await this.decoder.decode(decodeCtx);

        if (barcode) {
          this.cb(barcode);
        }
      } catch (err) {
        console.log({ decodeError: err.message });
      }

      if (this.running) {
        requestAnimationFrame(onFrame);
      }
    };

    return onFrame;
  }
}
